// src/LoginPage.tsx

import React, { useEffect } from "react";
import { Box, Button, Typography, Paper } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { useUser } from "../UserContext";
import { useNavigate } from "react-router-dom";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const { user } = useUser();

  useEffect(() => {
    if (user && accounts[0]) navigate("/document");
  }, [accounts, navigate, user]);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    instance.loginRedirect({
      scopes: [process.env.REACT_APP_MSAL_API_SCOPE as string],
    });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper sx={{ p: 4, width: "100%", maxWidth: 400 }}>
        <Typography variant="h5" align="center" mb={6} mt={4}>
         CRM AI Analysis
        </Typography>
        <Box
          component="form"
          gap={2}
          sx={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleLogin}
          noValidate
        >
          {user || accounts[0] ? (
            <Button variant="contained" disabled sx={{ height: 40 }}>
              Authenticating...
            </Button>
          ) : (
            <Button type="submit" variant="contained" sx={{ height: 40 }}>
              Sign In
            </Button>
          )}
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "0.75rem",
              position: "relative",
              color: "secondary",
              "&::before, &::after": {
                content: '""',
                position: "absolute",
                top: "50%",
                width: "35%",
                height: "1px",
                backgroundColor: "#D6DADB",
              },
              "&::before": {
                left: 0,
              },
              "&::after": {
                right: 0,
              },
            }}
            variant="body2"
            mt={2}
          >
            Developed by
          </Typography>
          <Box sx={{ margin: "0 auto" }}>
            <img src="/rnd-logo.png" alt="Logo" style={{ height: "80px" }} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;
