import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Home from "./pages/Home";
// import Playground from "./pages/Playground";

// import Chat from "./pages/Chat";
import Login from "./pages/LoginPage";
// import CreateBot from "./pages/CreateBot";
// import DataStorage from "./pages/DataStorage";

import ProtectedRoute from "./ProtectedRoute";

// import PrivateRoute from "./PrivateRoute";

import { useMsal } from "@azure/msal-react";
import { useUser } from "./UserContext";
import axiosInstance from "./utils/axiosInstance";
import { useEffect, useState } from "react";
import Forbidden from "./pages/ForbiddenPage";
import { Alert, Snackbar } from "@mui/material";
import DocumentPage from "./pages/DocumentPage";

window.addEventListener("error", (e) => {
  if (
    e.message ===
      "ResizeObserver loop completed with undelivered notifications." ||
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }
});

function App() {
  const { instance, accounts } = useMsal();
  const { user, setUser } = useUser();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    const requestProfileData = async () => {
      try {
        const response = await axiosInstance.get("/auth/me");
        setUser(response.data);
      } catch (e: any) {
        setAlertOpen(true);
        setAlertMessage(e.response?.data?.message || "An error occurred");

        instance.logout({
          account: instance.getActiveAccount(),
          postLogoutRedirectUri: "/",
          onRedirectNavigate: (url) => {
            setUser(null);
            return false;
          },
        });
      }
    };

    !user && accounts[0] && requestProfileData();
  }, [accounts, instance, setUser, user]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <BrowserRouter>
      <div>
        <Snackbar
          open={alertOpen}
          autoHideDuration={3000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleAlertClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/document"
            element={
              <ProtectedRoute>
                <DocumentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/document/:documentId"
            element={
              <ProtectedRoute>
                <DocumentPage />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/register" element={<Register />}></Route> */}
          {/* <Route path="/playground" element={<Playground />}></Route>
           */}
          {/* <Route path="/auth" element={<Auth />}></Route> */}
          {/* <Route path="/" element={(accounts[0]) ? <Chat />: <Login />}></Route> */}
          {/* <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat/:chatId"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />

          <Route
            path="/bot/:botId"
            element={
              <PrivateRoute allowedGroups={["admin"]}>
                <CreateBot />
              </PrivateRoute>
            }
          />

          <Route
            path="/data/:storageId"
            element={
              <PrivateRoute allowedGroups={["admin", "hr", "procurement"]}>
                <DataStorage />
              </PrivateRoute>
            }
          /> */}
          <Route path="/forbidden" element={<Forbidden />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
