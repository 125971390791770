// src/theme.tsx
import { createTheme } from "@mui/material/styles";

import "@fontsource/noto-sans-thai/100.css";
import "@fontsource/noto-sans-thai/400.css";
import "@fontsource/noto-sans-thai/500.css";
import "@fontsource/noto-sans-thai/700.css";

const theme = createTheme({
  typography: {
    fontFamily: "Noto Sans Thai",
  },
  palette: {
    primary: {
      main: "#6C2993",
    },
    secondary: {
      main: "#606769",
      light: "#E6E8E8",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          // backgroundColor: "#FFFFFF", // Set the background to white
          // borderBottom: "1px solid #E6E8E8", // Add a border to the bottom
        },
      },
    },
  },
});

export default theme;
