import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ForbiddenPage: React.FC = () => {
  const navigate = useNavigate();

  // Function to handle navigation back to home page
  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h1" color="error" gutterBottom>
          403
        </Typography>
        <Typography variant="h5" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="body1" gutterBottom>
          You do not have permission to access this page.
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          {process.env.REACT_APP_MSAL_AUTHORITY}
        </Typography> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoHome}
          sx={{ marginTop: 2 }}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default ForbiddenPage;
