import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the type for your user object (based on your API response)
interface User {
  id: string;
  name: string;
  email: string;
  groups: string[]
  // Add any other fields from your user object
}

// Define the type for the context
interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
}

// Create a context with a default value of null
const UserContext = createContext<UserContextType | undefined>(undefined);

// Custom hook for accessing the user context easily
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

// User provider to wrap your app with the context
export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
