import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig"; // Assuming you have this configured

// Create an MSAL instance for acquiring tokens
const msalInstance = new PublicClientApplication(msalConfig);

// Create an instance of axios with the API base URL from the .env file
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to add Authorization header with Bearer token
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      await msalInstance.initialize();

      const accounts = msalInstance.getAllAccounts();

      if (accounts.length > 0) {
        // Acquire token silently for the first account found
        const silentRequest = {
          account: accounts[0],
          scopes: [process.env.REACT_APP_MSAL_API_SCOPE as string], // Adjust scopes if necessary
        };

        const response = await msalInstance.acquireTokenSilent(silentRequest);
        const accessToken = response.accessToken;

        if (accessToken && config.headers) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }
    } catch (error) {
      console.error("Error acquiring token:", error);
      // Optionally handle token acquisition failure, e.g., redirect to login
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (window.location.pathname !== '/') {
        window.location.href = '/?error=401';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
