import { useMsal } from "@azure/msal-react";
import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { accounts } = useMsal();

  if (!accounts[0]) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
